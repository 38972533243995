<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        plain
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
          color="grey"
        >
          mdi-eye
        </v-icon>
      </v-btn>
    </template>
  
    <v-card color="white" class="pb-10">
      <v-card-title class="secondary--text">
       
          <v-row justify="start">
            <v-col cols="11">
              <v-chip
                  class="ma-2"
                  color="blue"
                  label
                  outlined
              >
                Registro #{{currentItem.id}}
              </v-chip>
            </v-col>
						<v-col  
              cols="1" 
              class="text-right"
							v-if="currentItem.usuario"
            >  {{currentItem.usuario.nome}}
              <v-avatar size="56">
                <v-img
                  :src="currentItem.avatar"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="1">
                    
              <v-btn 
                  outlined 
                  icon
                  @click="dialog=false"
              ><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
      </v-card-title>
      <v-card-text class="pa-4 mb-5">
        
        <v-container class="title">
          O seu registro foi enviado com sucesso! o prazo da resposta é de até 15 dias úteis.
        </v-container>
        <v-container>
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Tipo:</v-col>
            <v-col>
              {{currentItem.tipo.text}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Registro:</v-col>
            <v-col>
              {{currentItem.registro}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Setor:</v-col>
            <v-col>
              <v-chip
                class="ma-1"
                label
                outlined
                v-for="i in currentItem.setor"
                :key="`${i.id}s`"
              >
                  {{i.name}}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Quando ocorreu:</v-col>
            <v-col>
              {{dataOcorrencia | formatData}}
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Resultado:</v-col>
            <v-col>
              <v-chip
                  class="ma-2"
                  label
                  outlined
              >
                {{currentItem.resultado.text}}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="font-weight-bold text-right">Data deste registro:</v-col>
            <v-col>
              {{currentItem.created_at | formatData}}

            </v-col>
          </v-row>
          
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
//import { mapState } from 'vuex'
export default {
    name:'Registro',
    props:{
      currentItem:{}
      
    },
    components:{
    },
    data:()=>({
      dialog:false,
    }),
    computed:{
       /*
      ...mapState(['lista_status_analise']),
       headers(){
        return Object.keys(this.currentItem)
      }
     
      status_analise(){
        for(let i=0; i < this.lista_status_analise.length;i++){
          if(this.currentItem.status!=null){
            //console.log(this.lista_status_analise[i].id==this.currentItem.status.id)
            if(this.currentItem.status.id==this.lista_status_analise[i].id){
              console.log(this.currentItem.status.id,this.lista_status_analise[i].id)
              this.$set(this.lista_status_analise[i],'color','blue ligthen-4')
            }else{
              this.$set(this.lista_status_analise[i],'color','grey ligthen-4')
            }
          }          
        }
        return this.lista_status_analise
      }
      */
      dataOcorrencia(){
        return this.currentItem.data+'T'+this.currentItem.hora
      }
    },
    mounted(){
      /*var config = {
        method: 'get',
        url: 'informe/sugestoes/status/',
        headers: { 
            'Content-Type': 'application/json'
        },
      };
      this.$store.dispatch('listaStatusAnalise',config)
      */
    }
}
</script>